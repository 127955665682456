<div class="modal-pdv-rupture">
  <div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="pointer float-end mb-2" (click)="export(modalType)">
      <img src="assets/images/icons/external.svg" alt="Exporter">
    </div>
    <ng-template *ngxPermissionsOnly="permissionName">
      <div class="pointer float-end mb-2" (click)="export(modalType)">
        <img src="assets/images/icons/external.svg" alt="Exporter">
      </div>
    </ng-template>
    <div class="py-4">
      <div class="custom-table table-responsive table__territoire table__with__jauge">
        <table class="table table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="modalType === 'territoire'">
          <thead>
          <tr>
            <th scope="col">Territoires</th>
            <th scope="col">Nb banque en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Distributeur  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Distributeur  Non paybale en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb autre Distributeur  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux Banque  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux distributeurs  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux distributeurs Non payable  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Autre PDV en rupture<br>( FCFA TTC )</th>
            <th scope="col">Nb Banque Agent en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Agent relai en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Agent Super relai en rupture <br>( FCFA TTC )</th>
          </tr>
          </thead>
          <tbody class="text__mini">
          <tr *ngFor="let territoire of tableItems">
            <td>{{ territoire.territoireName }}</td>
            <td>{{ territoire.bankDistNbRupture }}</td>
            <td class="bg__jauge" [style]="'--jauge-width:' + defineWidthStockGauge(territoire.dealerDistNbRupture, mostGreaterValue)">{{ territoire.dealerDistNbRupture }}</td>
            <td>{{ territoire.dealerNonPayDistNbRupture }}</td>
            <td>{{ territoire.otherDistNbRupture }}</td>
            <td>{{ territoire.pdvBankNbRupture }}</td>
            <td>{{ territoire.pdvDistNbRupture}}</td>
            <td>{{ territoire.pdvDistNonPayNbRupture}}</td>
            <td>{{ territoire.pdvOtherNbRupture }}</td>
            <td>{{ territoire.agentBankNbRupture }}</td>
            <td>{{ territoire.agentRelaiNbRupture }}</td>
            <td>{{ territoire.agentSuperRelaiNbRupture }}</td>
          </tr>
          </tbody>
          <tbody *ngIf="tableItems.length === 0">
          <tr><td colspan="12" class="text-center">Aucune donnée trouvé</td></tr>
          </tbody>
          <ngx-ui-loader [loaderId]="'pdv-rupture-table'" fgsColor="#ff7900" pbColor="#ff7900"></ngx-ui-loader>
        </table>
        <table class="table table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="modalType === 'locality'">
          <thead>
          <tr>
            <th scope="col">Localité</th>
            <th scope="col">Nb banque en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Distributeur  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Distributeur  Non paybale en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb autre Distributeur  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux Banque  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux distributeurs  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux distributeurs Non payable  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Autre PDV en rupture<br>( FCFA TTC )</th>
            <th scope="col">Nb Banque Agent en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Agent relai en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Agent Super relai en rupture <br>( FCFA TTC )</th>
          </tr>
          </thead>
          <tbody class="text__mini">
          <tr *ngFor="let locality of tableItems">
            <td>{{ locality.localityName }}</td>
            <td>{{ locality.bankDistNbRupture }}</td>
            <td class="bg__jauge" [style]="'--jauge-width:' + defineWidthStockGauge(locality.dealerDistNbRupture, mostGreaterValue)">{{ locality.dealerDistNbRupture ?? 0 }}</td>
            <td>{{ locality.dealerNonPayDistNbRupture }}</td>
            <td>{{ locality.otherDistNbRupture }}</td>
            <td>{{ locality.pdvBankNbRupture }}</td>
            <td>{{ locality.pdvDistNbRupture}}</td>
            <td>{{ locality.pdvDistNonPayNbRupture}}</td>
            <td>{{ locality.pdvOtherNbRupture }}</td>
            <td>{{ locality.agentBankNbRupture }}</td>
            <td>{{ locality.agentRelaiNbRupture }}</td>
            <td>{{ locality.agentSuperRelaiNbRupture }}</td>
          </tr>
          </tbody>
          <tbody *ngIf="tableItems.length === 0">
          <tr><td colspan="12" class="text-center">Aucune donnée trouvé</td></tr>
          </tbody>
          <ngx-ui-loader [loaderId]="'pdv-rupture-table'" fgsColor="#ff7900" pbColor="#ff7900"></ngx-ui-loader>
        </table>
        <table class="table table-striped" datatable [dtOptions]="dtOptions" ngxUiLoaderBlurred [blur]="10" [dtTrigger]="dtTrigger" *ngIf="modalType === 'distributeur'">
          <thead>
          <tr>
            <th scope="col">NUMERO DIST</th>
            <th scope="col">Nom & Prénom(s)</th>
            <th scope="col">Nb banque en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Distributeur  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Distributeur  Non paybale en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb autre Distributeur  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux Banque  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux distributeurs  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb PDV rattachés aux distributeurs Non payable  en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Autre PDV en rupture<br>( FCFA TTC )</th>
            <th scope="col">Nb Banque Agent en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Agent relai en rupture <br>( FCFA TTC )</th>
            <th scope="col">Nb Agent Super relai en rupture <br>( FCFA TTC )</th>
          </tr>
          </thead>
          <tbody class="text__mini">
          <tr *ngFor="let distributeur of tableItems">
            <td>{{ distributeur.numero }}</td>
            <td>{{ distributeur.distributeur }}</td>
            <td>{{ distributeur.bankDistNbRupture }}</td>
            <td class="bg__jauge" [style]="'--jauge-width:' + defineWidthStockGauge(distributeur.dealerDistNbRupture, mostGreaterValue)">{{ distributeur.dealerDistNbRupture }}</td>
            <td>{{ distributeur.dealerNonPayDistNbRupture }}</td>
            <td>{{ distributeur.otherDistNbRupture}}</td>
            <td>{{ distributeur.pdvBankNbRupture }}</td>
            <td>{{ distributeur.pdvDistNbRupture}}</td>
            <td>{{ distributeur.pdvDistNonPayNbRupture}}</td>
            <td>{{ distributeur.pdvOtherNbRupture }}</td>
            <td>{{ distributeur.agentBankNbRupture }}</td>
            <td>{{ distributeur.agentRelaiNbRupture }}</td>
            <td>{{ distributeur.agentSuperRelaiNbRupture }}</td>
          </tr>
          </tbody>
          <tbody *ngIf="tableItems.length === 0">
          <tr><td colspan="12" class="text-center">Aucune donnée trouvé</td></tr>
          </tbody>
          <ngx-ui-loader [loaderId]="'pdv-rupture-table'" fgsColor="#ff7900" pbColor="#ff7900"></ngx-ui-loader>
        </table>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
  </div>
</div>
