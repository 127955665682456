import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, map, Subject, switchMap } from 'rxjs';
import { IDistributeurStock } from 'src/app/interface/distributeur.interface';
import { ITerritoireStock } from 'src/app/interface/territoire.interface';
import { IModalPDVRuptureDatas } from 'src/app/interface/utils.interface';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { LastUpdateDataService } from 'src/app/services/last-update-data/last-update-data.service';
import { PdvService } from 'src/app/services/pdv/pdv.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { FiltersService } from 'src/app/views/authenticated/pages/stock-level/filters/filters.service';

@Component({
  selector: 'app-modal-pdv-rupture',
  templateUrl: './modal-pdv-rupture.component.html',
  styleUrls: ['./modal-pdv-rupture.component.scss']
})
export class ModalPdvRuptureComponent implements OnInit {
  modalTitle: string = ""
  modalType: string = ""
  tableItems: Array<any> = []
  tableLabels: Array<any> = []
  mostGreaterValue: number;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  datatableIsInitialized: boolean = false;
  lastStartDate: string = ''
  lastEndDate: string = ''
  lastHour: { start: string, end: string } = { start: "", end: "" }
  isLastMonth: boolean = false
  isLastDate: boolean = true
  totalItems: number = 0;
  currentPage: number = 0;
  itemPerPage: number = 20;
  globalDataFilterParams = {
    regions: [] as string[],
    territoires: [] as string[],
    distributeurs: [] as string[],
    pdvs: [] as string[],
  } as any
  permissionName: string = "PAGE_PDV_RUPTURE_EXPORT_VUE_TERRITOIRE"
  @Input() datas: any;
  constructor(
    public activeModal: NgbActiveModal,
    private utilitiesService: UtilitiesService,
    private pdvService: PdvService,
    private toastr: ToastrService,
    private lastUpdateDataService: LastUpdateDataService,
    private filtersService: FiltersService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      search: true,
      paging: true,
      responsive: false,
      ordering: false,
      destroy: true,
      language: {
        url: '/assets/i18n/datatable.fr.json',
        search: 'Rechercher'
      }
    };
    if (this.datas) {
      const datasTyped = <IModalPDVRuptureDatas>this.datas
      this.modalTitle = datasTyped.modalTitle
      this.modalType = datasTyped.modalType
      this.lastStartDate = datasTyped.lastStartDate
      this.lastEndDate = datasTyped.lastEndDate
      this.lastHour = datasTyped.lastHour
      this.isLastDate = datasTyped.isLastDate
      this.globalDataFilterParams = datasTyped.globalDataFilterParams
      this.handleData(datasTyped.modalType)
    }
    switch (this.modalType) {
      case 'territoire':
        this.permissionName = "PAGE_PDV_RUPTURE_EXPORT_VUE_TERRITOIRE"
        break;
      case 'locality':
        this.permissionName = "PAGE_PDV_RUPTURE_EXPORT_VUE_TERRITOIRE"
        break;
      case 'distributeur':
        this.permissionName = "PAGE_PDV_RUPTURE_EXPORT_VUE_DISTRIBUTEUR"
        break;

      default:
        break;
    }
  }

  handleData(type: string) {
    switch (type) {
      case 'territoire':
        this.getAllTerritoireStock()
        break;
      case 'locality':
        this.getAllLocalityStock()
        break;
      case 'distributeur':
        this.getAllDistributeurStock()
        break;
    }
  }

  getAllTerritoireStock(index: number = 0, size: number = 20) {
    this.ngxUiLoaderService.startLoader('pdv-rupture-table')
    const getAllTeritoireStockRequest = this.pdvService.getAllTerritoireStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, 0, 0, this.globalDataFilterParams).subscribe({
      next: (response: any) => {
        this.tableItems = []
        if (!response.hasError && response.hasOwnProperty('items')) {
          //Transform Data
          if (response.items.length > 0) {
            this.totalItems = response.count
            response.items.map((elem: any) => {
              let tmpItem = {} as ITerritoireStock
              tmpItem = {
                ...elem[Object.keys(elem)[0]],
                territoireName: Object.keys(elem)[0]
              }
              this.tableItems.push(tmpItem)
            })
          }
          this.defineMostGreaterStocksValues()
          this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
          if (!this.datatableIsInitialized) {
            this.dtTrigger.next(this.dtOptions)
            this.datatableIsInitialized = true
          }
          // return;
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les stocks des territoires', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })
  }

  getAllLocalityStock(index: number = 0, size: number = 20) {
    this.ngxUiLoaderService.startLoader('pdv-rupture-table')
    const getAllTeritoireStockRequest = this.pdvService.getAllLocalityStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, 0, 0, this.globalDataFilterParams).subscribe({
      next: (response: any) => {
        this.tableItems = []
        if (!response.hasError && response.hasOwnProperty('items')) {
          //Transform Data
          if (response.items.length > 0) {
            this.totalItems = response.count
            response.items.map((elem: any) => {
              let tmpItem = {} as ITerritoireStock
              tmpItem = {
                ...elem[Object.keys(elem)[0]],
                localityName: Object.keys(elem)[0]
              }
              this.tableItems.push(tmpItem)
            })
          }
          this.defineMostGreaterStocksValues()
          this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
          if (!this.datatableIsInitialized) {
            this.dtTrigger.next(this.dtOptions)
            this.datatableIsInitialized = true
          }
          // return;
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les stocks des localités', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })
  }

  getAllDistributeurStock() {
    this.ngxUiLoaderService.startLoader('pdv-rupture-table')
    const getAllDistributeurStockRequest = this.pdvService.getAllDistributeurStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, this.currentPage, 0, this.globalDataFilterParams).subscribe({
      next: (response: any) => {
        this.tableItems = []
        if (!response.hasError && response.hasOwnProperty('items')) {
          //Transform Data
          if (response.items.length > 0) {
            response.items.map((elem: any) => {
              let tmpItem = {} as IDistributeurStock
              tmpItem = {
                ...elem[Object.keys(elem)[0]],
                numero: Object.keys(elem)[0]
              }
              this.tableItems.push(tmpItem)
            })
          }

          this.defineMostGreaterStocksValues()
          this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
          if (!this.datatableIsInitialized) {
            this.dtTrigger.next(this.dtOptions)
            this.datatableIsInitialized = true
          }
          // return;
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les stocks des distributeurs', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })
  }

  defineMostGreaterStocksValues() {
    const returnStockStatValue = (item: any) => Number(item.dealerDistNbRupture.replace(' ', '').replace(',', '.'))

    this.mostGreaterValue = Math.max(
      ...this.tableItems.map(returnStockStatValue)
    )
  }

  /**
   * Fefine Style with width property of stats gauge calculate with current value and the max value
   *
   * @param value
   * @param mostGreaterStockValue
   * @returns
   */
  defineWidthStockGauge(value: number | string, mostGreaterStockValue: number): string {
    if (typeof value === 'string') {
      value = Number(value.replace(' ', '').replace(',', '.'))
    }
    return `${value * 100 / mostGreaterStockValue}%`
  }

  getFirstPageData() {
    if (this.currentPage !== 0) {
      this.currentPage = 0
      this.getAllDistributeurStock()
    }
  }
  getPageData(pageNumber: number) {
    this.currentPage = pageNumber
    if (this.currentPage !== 0) {
      this.getAllDistributeurStock()
    }
  }

  export(dataType: string): void {
    this.ngxUiLoaderService.startLoader('pdv-rupture-table')

    switch (dataType) {
      case 'region':
        this.exportData('dashbord/exportVueRegionRupture')
        break;
      case 'territoire':
        this.exportData('dashbord/exportVueTerritoireRupture')
        break;
      case 'distributeur':
        this.exportData('dashbord/exportVueDistributeurRupture')
        break;
      case 'pdv_agent':
        this.exportData('omerPdvHist/exportPdv')
        break;

      default:
        break;
    }
  }

  exportData(dataType: string): void {
    const exportDataRequest = this.dashboardService.exportPdvData(
      dataType,
      this.lastStartDate,
      this.lastEndDate,
      this.lastHour,
      this.isLastDate,
      this.isLastMonth,
      this.currentPage,
      this.itemPerPage,
      this.globalDataFilterParams
    ).subscribe({
      next: (response: any) => {
        if (response) {
          //Transform Data
          this.utilitiesService.downloadFile(response.body)
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à exporter les données.', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      },
      complete: () => {
        //this.loginInProgress = false
        this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
      }
    })
  }

}
